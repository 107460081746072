<template>
	<Fragment></Fragment>
</template>
<script>
export default {
	name: 'Logout.vue',
	async mounted() {
		const isCurrent = this.$store.getters.isCurrentUser;
		if (isCurrent) await this.$store.dispatch('logout', {});
		await this.$store.commit('INITIALIZE_ADD_EXTENSION');
		await this.$store.commit('INITIALIZE_APPLY');
		await this.$store.commit('INITIALIZE_ORDER');
		await this.$store.commit('INITIALIZE_USER');
		await this.$store.commit('INITIALIZE_COMMON');
		await this.$store.commit('INITIALIZE_LOGIN');
		sessionStorage.removeItem('authToken');
		this.$store.commit('CLEAR_LINK_CODES');
		await this.$router.replace('/login');
	},
};
</script>
